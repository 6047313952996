var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c("p", { staticClass: "login-form__title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.emailSent && !_vm.error
                ? "Your Password is reset. Please check your email."
                : "Forgot password?"
            ) +
            " "
        ),
        _vm.emailSent && !_vm.error
          ? _c(
              "span",
              {
                staticClass: "form__secondary-button",
                on: {
                  click: function ($event) {
                    return _vm.setLogin()
                  },
                },
              },
              [_vm._v("Login")]
            )
          : _vm._e(),
      ]),
      !_vm.emailSent || (_vm.emailSent && _vm.error)
        ? _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSignin.apply(null, arguments)
                },
              },
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "text",
                  rules: [_vm.rules.required, _vm.rules.email],
                  required: "",
                  placeholder: "Email",
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c("div", { staticClass: "form-btns__wrap" }, [
                _c("div", { staticClass: "submit-wrap" }, [
                  _c("input", {
                    attrs: {
                      type: "submit",
                      disabled: !_vm.isFormValid,
                      value: "Continue",
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "form__secondary-button",
                    on: {
                      click: function ($event) {
                        return _vm.setLogin()
                      },
                    },
                  },
                  [_vm._v("Login")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }