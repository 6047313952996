<template>
  <div class="login-form">
    <p class="login-form__title">
      {{
        emailSent && !error
          ? "Your Password is reset. Please check your email."
          : "Forgot password?"
      }}

      <span
        v-if="emailSent && !error"
        class="form__secondary-button"
        @click="setLogin()"
        >Login</span
      >
    </p>

    <v-form
      @submit.prevent="onSignin"
      ref="form"
      v-if="!emailSent || (emailSent && error)"
    >
      <v-text-field
        type="text"
        :rules="[rules.required, rules.email]"
        required
        placeholder="Email"
        v-model="email"
      ></v-text-field>

      <div class="form-btns__wrap">
        <div class="submit-wrap">
          <input type="submit" :disabled="!isFormValid" value="Continue" />
        </div>

        <div class="form__secondary-button" @click="setLogin()">Login</div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      password: "",
      isFormValid: false,
      rules: {
        required: value => !!value || "Field is required",
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Invalid e-mail."
        }
      },
      emailSent: false
    }
  },

  props: ["id", "setLogin"],

  computed: {
    ...mapGetters("auth", ["error"]),

    formContent() {
      return `${this.email}`
    }
  },

  watch: {
    formContent(val, prevVal) {
      if (val !== prevVal) {
        if (this.$refs.form.validate()) {
          this.isFormValid = true
        } else {
          this.isFormValid = false
        }
      }
    }
  },

  methods: {
    ...mapActions("auth", ["forgotPass"]),

    async onSignin() {
      await this.forgotPass({ email: this.email })
      this.emailSent = true
    }
  }
}
</script>
